<template>
  <div id="humidityRecord">
    <el-dialog
      :title="humidityRecordFormTitle"
      width="1200px"
      :visible.sync="humidityRecordDialogVisible"
      :close-on-click-modal="false"
      @close="humidityRecordDialogClose"
    >
      <el-form
        ref="humidityRecordFormRef"
        :model="humidityRecordForm"
        :rules="humidityRecordFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="测定房间" prop="roomName">
              <el-input v-model="humidityRecordForm.roomName" placeholder="请输入测定房间" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="房间等级" prop="roomLevel">
              <el-input v-model="humidityRecordForm.roomLevel" placeholder="请输入房间等级" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="设备名称" prop="deviceName">
              <el-input v-model="humidityRecordForm.deviceName" placeholder="请输入设备名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="设备编码" prop="deviceNo">
              <el-input v-model="humidityRecordForm.deviceNo" placeholder="请输入设备编码" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="温度洁净级别" prop="temLevel">
              <el-input v-model="humidityRecordForm.temLevel" placeholder="请输入温度洁净级别" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="湿度洁净级别" prop="humidityLevel">
              <el-input v-model="humidityRecordForm.humidityLevel" placeholder="请输入湿度洁净级别" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <vxe-toolbar v-if="humidityRecordFormTitle !== '人员需求申请表详情'">
            <template #buttons>
              <vxe-button @click="insertRowEvent()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            keep-source
            :data="humidityRecordDetailList"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <vxe-table-column
              field="monitoringTime"
              title="监测时间"
              :edit-render="{
                name: '$input',
                props: {
                  type: 'datetime',
                  clearable: true,
                  labelFormat: 'yyyy-MM-dd HH:mm',
                  valueFormat: 'yyyy-MM-dd HH:mm'
                }
              }"
            />
            <vxe-table-column
              field="temperature"
              title="温度"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="humidity"
              title="湿度"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="conclusion"
              title="结论"
              :edit-render="{
                name: '$select',
                props: {clearable: true},
                options: [
                  {value: 1, label: '合格'},
                  {value: 2, label: '不合格'}
                ]
              }"
            />
            <vxe-table-column
              field="recorder"
              title="记录人"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column v-if="humidityRecordFormTitle !== '人员需求申请表详情'" title="操作" width="100">
              <template #default="{ row }">
                <template>
                  <vxe-button @click="removeRowEvent(row)">
                    删除
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="humidityRecordDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="humidityRecordFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="测定房间">
        <el-input v-model="searchForm.roomName" placeholder="请输入测定房间" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="humidityRecordPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="roomName" label="测定房间" />
      <el-table-column prop="roomLevel" label="房间等级" />
      <el-table-column prop="deviceName" label="设备名称" />
      <el-table-column prop="deviceNo" label="设备编码" />
      <el-table-column prop="temLevel" label="温度洁净级别" />
      <el-table-column prop="humidityLevel" label="湿度洁净级别" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="humidityRecordPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addHumidityRecord, deleteHumidityRecord, updateHumidityRecord, selectHumidityRecordInfo, selectHumidityRecordList } from '@/api/universal/humidityRecord'

export default {
  data () {
    return {
      humidityRecordDialogVisible: false,
      humidityRecordFormTitle: '',
      humidityRecordForm: {
        id: '',
        roomName: '',
        roomLevel: '',
        deviceName: '',
        deviceNo: '',
        temLevel: '',
        humidityLevel: '',
        humidityJson: ''
      },
      humidityRecordFormRules: {
        roomName: [{ required: true, message: '测定房间不能为空', trigger: ['blur', 'change']}]
      },
      humidityRecordPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        roomName: ''
      },
      humidityRecordDetailList: []
    }
  },
  created () {
    selectHumidityRecordList(this.searchForm).then(res => {
      this.humidityRecordPage = res
    })
  },
  methods: {
    humidityRecordDialogClose () {
      this.$refs.humidityRecordFormRef.resetFields()
      this.humidityRecordDetailList = []
    },
    humidityRecordFormSubmit () {
      if (this.humidityRecordFormTitle === '人员需求申请表详情') {
        this.humidityRecordDialogVisible = false
        return
      }
      this.$refs.humidityRecordFormRef.validate(async valid => {
        if (valid) {
          this.humidityRecordForm.humidityJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
          if (this.humidityRecordFormTitle === '新增人员需求申请表') {
            await addHumidityRecord(this.humidityRecordForm)
          } else if (this.humidityRecordFormTitle === '修改人员需求申请表') {
            await updateHumidityRecord(this.humidityRecordForm)
          }
          this.humidityRecordPage = await selectHumidityRecordList(this.searchForm)
          this.humidityRecordDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.humidityRecordFormTitle = '新增人员需求申请表'
      this.humidityRecordDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteHumidityRecord(row.id)
        if (this.humidityRecordPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.humidityRecordPage = await selectHumidityRecordList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.humidityRecordFormTitle = '修改人员需求申请表'
      this.humidityRecordDialogVisible = true
      this.selectHumidityRecordInfoById(row.id)
    },
    handleInfo (index, row) {
      this.humidityRecordFormTitle = '人员需求申请表详情'
      this.humidityRecordDialogVisible = true
      this.selectHumidityRecordInfoById(row.id)
    },
    selectHumidityRecordInfoById (id) {
      selectHumidityRecordInfo(id).then(res => {
        this.humidityRecordForm.id = res.id
        this.humidityRecordForm.roomName = res.roomName
        this.humidityRecordForm.roomLevel = res.roomLevel
        this.humidityRecordForm.deviceName = res.deviceName
        this.humidityRecordForm.deviceNo = res.deviceNo
        this.humidityRecordForm.temLevel = res.temLevel
        this.humidityRecordForm.humidityLevel = res.humidityLevel
        this.humidityRecordDetailList = res.humidityRecordList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectHumidityRecordList(this.searchForm).then(res => {
        this.humidityRecordPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectHumidityRecordList(this.searchForm).then(res => {
        this.humidityRecordPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectHumidityRecordList(this.searchForm).then(res => {
        this.humidityRecordPage = res
      })
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1)
      await this.$refs.xTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    }
  }
}
</script>

<style>
</style>
